.wrapper-popup {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba($color: $white, $alpha: 0.95);
  height: 100vh;
  width: 100%;
  z-index: 999;

  & .wrapper-popup-card {
    position: relative;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid $grayMain;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    height: auto;
    padding: 68px 20px 80px 20px;
    background-color: $white;
    max-width: 658px;
  }

  & .defaultWidth {
    max-width: 520px;
  }

  & .width-654 {
    max-width: 654px;
  }
  & .width-640 {
    max-width: 640px;
  }

  & .wrapper-popup-btn {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 55px 1fr 55px;
    grid-template-rows: minmax(200px, max-content);

    & .wrapper-popup-image {
      justify-self: center;
    }

    & .wrapper-popup-btn-block {
      width: 48px;
      height: 48px;
      border: 1px solid $grayLight;
      border-radius: 50%;
      cursor: pointer;
      background-color: transparent;
      padding: 0;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: $greenBold;
      }
      & i {
        &::before {
          color: $grayLight;
          font-size: 25px;
        }
      }
    }
  }

  & .wrapper-popup-description {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 48px 0 10px;
    color: $greenBold;

    & .description-title {
      color: $greenBold;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }

    & .description-list {
      color: $blueLight;
      font-weight: 400;
      line-height: 24px;
    }

    & .step-block {
      align-self: center;
      margin-top: 25px;
      display: flex;
      gap: 10px;

      & .step-item {
        width: 40px;
        height: 4px;
        background-color: $grayLight;
        border-radius: 4px;
        &.active {
          background-color: $mainGreen;
        }
      }
    }
  }
}
