.sfield {
  display: flex;
  margin-bottom: 16px;
  align-items: center;

  &__label {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $color-font;
    padding-right: 20px;
  }

  &__input {
    border: 1px solid $color-font;
    border-radius: 4px;
    width: 110px;
    height: 28px;
    margin-right: 10px;
    padding-left: 10px;
    font-weight: 400;
    line-height: 14px;
    color: $color-font;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__input-error {
    border: 1px solid $lightRed;
  }

  &__value-name {
    position: absolute;
    right: 20px;
    font-weight: 400;
    line-height: 14px;
    color: $color-font;
  }
}
