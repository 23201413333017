.valitator-item {
  &__24 {
    width: 24%;
  }

  &__19 {
    width: 19%;
  }

  &__box {
    width: 100%;
    display: flex;
    align-items: center;
    height: 64px;
    border: 1px solid $grayMain;
    border-radius: 8px;
    padding-left: 12px;
    margin-bottom: 0 !important;
  }

  &__main-text {
    font-weight: 400;
    line-height: 21px;
    color: $color-font;
  }

  &__container {
    display: flex;
    align-items: center;
    position: relative;
  }
}

.trash {
  position: absolute;
  width: 44px;
  height: 44px;
  right: -50px;
}

.edit {
  position: absolute;
  width: 44px;
  height: 44px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
