.valitator-item {
  &__60 {
    width: 60%;
  }

  &__30 {
    width: 30%;
  }

  &__10 {
    width: 10%;
  }

  &__box {
    width: 100%;
    display: flex;
    align-items: center;
    height: 64px;
    border: 1px solid $grayMain;
    border-radius: 8px;
    padding-left: 12px;
    margin-bottom: 0 !important;
  }

  &__main-text {
    font-weight: 400;
    line-height: 21px;
    color: $color-font;
    &.overflow{
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width:173px;
    }
  }

  &__container {
    min-width: 500px;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
  }
}

.trash {
  position: absolute;
  width: 44px;
  height: 44px;
  right: -50px;
}
