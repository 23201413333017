// Colors HEX
$white: #ffffff;
$dark: #252429;
$color-font: #4d4d4c;
$darkBlue: #1a1156;
$lightBlue: #2ec3e9;
$activeLightBlue: #45d4f8;
$tile_title_bottom_border: #797979;
$color-bg-input: #f5f5f5;
$green: #65b042;
$lightGreen: #3fdd7e;
$mostLightGreen:#7BE495;
$darkGreen: #173409;
$orange: #FABC73;
$blue: #2e5de9;
$hugeBlue: #4087C9;
$red: #ff0000;
$lightRed: #ff6c58;
$transparent: transparent;
$login_background: #d5f3fb;
$map_color: #5d5a6d;
$btn_back: #dfdfdf;
$confirmed: #49c179;
$denied: #ff586c;
$lightPink: #ffeef0;
$mainGreen: #329d9c;
$greenBold: #1c505d;
$grayMain: #d5d9e7;
$grayLight: #cfd6e4;
$grayLightShade: #f3f3f2;
$grayMedium: #a6b0c3;
$grayMediumShade: #CECECD;
$mainText: #5b6075;
$mainInputColor: #5b6075;
$blueLight: #58667e;
$turquoiseLight: #2dc2e8;
$yellowLight: #fecb90;
$black: #000000;
$peach: #FF8080;
$color-icon-gray: #cecece;
$color-activeIcon-gray: #2ec3e9;
$color-bg-menu: #d5f3fb;
$grey: #cccccc;
$color-font-copy: #8d8d8d;
$color-bg-dash: #ffffff;
$point-color-gray: #c4c4c4;
$errorShadeRed: #D54F47;
$lightGrey: #f3f3f3;
$secondGrey: #dddddd;
$color-for-link:#AEB0B0;
$map_geographies_fill_dark: #3f3e40;
$map_geographies_stroke_dark: #3f3e40;
$q_icon_bg: #828282;
$bg_select :#EEF0F5;

// Colors RGBA
$tile_background: rgba(238, 238, 238, 1);
$tile_background_dark: #4d4c4f;
$border_color_gray: rgba(0, 0, 0, 0.79);
$page_background: #ffffff;
$page_background_dark: #252429;
$color-country:rgba(64, 64, 64, 0.05);
$tile_title: rgba(64, 64, 64, 0.5);
$tile_title_bottom_border: rgba(215, 215, 215, 1);
$title-desc: rgba(91, 96, 117, 0.6);
// Fonts
$common-font: "Poppins", sans-serif;
