.carbon{
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 30px;
  &__phases{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    font-size: 16px;
    color: $greenBold;
  }
  &__loader {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }


}


