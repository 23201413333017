.download-zone {
  &__list {
    display: flex !important;
    justify-content: space-between;
    max-width: 380px;
    width: 100%;
    padding: 14px 19px !important;
    border-radius: 6px !important;
    opacity: 1 !important;
    color: $mainText !important;
    font-size: 14px !important;
  }

  label {
    height: auto;
    margin-bottom: 0;
  }

  &__download-zone {
    @include theme-aware("background", "mainGreen");
    border-radius: 6px;
    font-weight: 700;
    padding: 10px;
    line-height: 21px;
  }

  &__download-zone-init {
    height: 60px;
    background-color: $white;
    @include theme-aware("background", "mainGreen");
    @include theme-aware("border-color", "mainGreen");
    border: 1px solid;
    @include theme-aware("color", "activeStepFont");
  }

  &__wrapper {
    max-width: 180px;
    width: 100%;
  }

  &__btn {
    padding: 7px 21px;
    border: 1px solid $mainGreen;
    background: $white;
    color: $mainGreen;
    border-radius: 8px;
    font-weight: 700;

    &:hover{
      background: $mainGreen;
      color:  $white;
    }
  }
}
