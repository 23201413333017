.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  border-right: 1px solid $grayMain;

  .nav {
    width: 280px;
    height: max-content;
    display: flex;
    gap: 25px;
    flex-direction: column;
    padding: 20px 5px 20px 40px;
  }

  .menu-link {
    font-weight: 500;
    line-height: 21px;
    color: $color-font;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    opacity: 1;

    &:hover {
      transition: opacity .3ms ease-in-out;
      opacity: 0.9;
    }

    &.active {
      color: $greenBold;
    }

    &_img {
      height: 24px;
      width: 24px;
    }
  }


  &-content {
    width: calc(100% - 280px);
    margin: 0;
  }

  &-child {
    padding-left: 30px;
  }
}

//.horizontal {
//  flex-direction: row !important;
//}
//
//.val-modal {
//  &__container {
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//    background: $white;
//  }
//
//  &__desc {
//    display: flex;
//  }
//
//  &__button {
//    width: 350px;
//    &-title {
//      font-weight: 700;
//      line-height: 24px;
//      color: $white;
//    }
//  }
//
//  &__title {
//    font-weight: 700;
//    line-height: 21px;
//    color: $darkBlue;
//    margin-bottom: 16px;
//  }
//
//  &__msg {
//    font-weight: 400;
//    line-height: 21px;
//    color: $darkBlue;
//    margin-bottom: 24px;
//    margin-left: 6px;
//  }
//
//  &__icon {
//    height: 20px;
//    width: 20px;
//  }
//}
