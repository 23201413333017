.custom-switch {
  &__names {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grayMain;
    height: 100%;
  }
}
