.input-field {
  width: 100%;
  position: relative;
  line-height: 48px;
  margin-bottom: 22px;
  color: #a6b0c3;
  display: flex;
  flex-direction: column;
  &::placeholder {
    color: #a6b0c3;
  }
  &:nth-child(2) {
    margin-bottom: 15px;
  }

  .input-field_input {
    position: relative;
    width: 100%;
    input {
      width: 100%;
      padding: 15px 12px;
      background-color: white;
      border: 1px solid #d5d9e7;
      border-radius: 8px;

      &:focus ~ .text-field_label,
      &:valid ~ .text-field_label {
        color: #00dfc4;
        transform: translateX(10px) translateY(-7px);
        font-size: 12px;
        line-height: 18px;
        padding: 0 10px;
        background: white;
        border-left: 1px solid #329d9c;
        border-right: 1px solid #329d9c;
      }

      &:focus,
      &:valid {
        border-color: #d5d9e7;
      }
    }

    .input-field_label {
      left: 0;
      pointer-events: none;
      transition: 0.5s;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 8px;
      color: #1c505d;
      text-align: left;
    }
  }
}
.input-field-error {
  input{
    border-color: red !important;

  }
  &-message {
    border-color: red !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    height: 16px;
    display: flex;
    align-items: center;
    color: #ff8080;
    bottom: -16px;
  }
}
input[type='checkbox'] {
  accent-color: #329d9c;
  height: 20px;
  width: 20px;
}
.checkbox-error {
  outline: 1px solid red;
}
