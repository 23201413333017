.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid $grayMain;
  padding: 0 40px;
  img{
    width: 78px;
    height: 24px;
  }

  &.not-border {
    border-bottom: none;
    justify-content: flex-end;
  }

  .btn_sign-out {
    width: max-content;
    padding: 8px 30px;
    height: 40px;
    font-weight: 600;
    color: $mainGreen;
    text-transform: capitalize;
    cursor: pointer;
    border: 1px solid $mainGreen;
    border-radius: 24px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    outline: none;
    background-color: transparent;

    &:hover {
      opacity: .9;
      transition: opacity 0.3s ease-in-out;
    }
  }
}





