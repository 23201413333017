.edit-lec {
  &__wrapper {
    max-width: 880px;
    margin: 0 auto;
  }
  &__block {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    position: relative;
    width: 100%;
    margin-top: 37px;
    &.mt-small {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
  &__block-zone {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  &__block-wrapper {
    padding: 40px 40px 20px 41px;
    border: 1px solid $grayMain;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 24px;
    & > span {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: $greenBold;
    }
  }
  &__wrapper-btn {
    width: 118px;
    margin-right: calc(-118px - 20px);
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__form-block {
    display: flex;
    justify-content: space-between;
    & > span {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.2;
      color: $greenBold;
      margin-bottom: 5px;
    }
    &.col {
      flex-direction: column;
    }
  }
  &__btn {
    display: flex;
    background: $white;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    max-height: 40px;
    border-color: $mainGreen;
    text-align: center;
    text-transform: capitalize;

    span {
      font-weight: 700;
      line-height: 1.7;
      color: $mainGreen;
    }

    &:hover {
      background: $mainGreen;

      span {
        color: $white;
      }
    }

    &.active {
      background: $mainGreen;
      span {
        color: $white;
      }
    }

    &.back {
      background: $grayMain;

      border-color: $grayMain;
      span {
        color: $white;
        margin-left: 16px;
      }
    }
  }

  &__input {
    width: 50%;
    flex-basis: 50%;
    margin-bottom: 5px;

    &.full {
      width: 100%;
      flex-basis: 100%;
    }

    &.pre-full {
      width: 100%;
      flex-basis: 100%;
    }

    &.rainfall {
      flex-basis: 74%;
      width: 74%;
      justify-content: space-between;
    }
  }

  &__textarea {
    padding: 15px 12px;
    background-color: white;
    border: 1px solid #d5d9e7;
    border-radius: 8px;
    min-height: 91px;
    font-weight: 400;
    font-family: 'Poppins';

    &:focus ~ .text-field_label,
    &:valid ~ .text-field_label {
      color: #00dfc4;
      transform: translateX(10px) translateY(-7px);
      font-size: 12px;
      line-height: 18px;
      padding: 0 10px;
      background: white;
      border-left: 1px solid #329d9c;
      border-right: 1px solid #329d9c;
    }

    &:focus,
    &:valid {
      border-color: #d5d9e7;
    }

    &.error {
      border-color: $peach;
    }
  }

  &__checkbox {
    display: flex;
    padding-left: 35px;
    align-items: center;
    padding-right: 32px;
    height: 24px !important;
    position: relative;
    color: $greenBold;
    cursor: pointer;
    user-select: none;

    &.not-use {
      span {
        opacity: 0.6;
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: -0px;
      height: 22px;
      width: 22px;
      border-radius: 3px;
      border: 1px solid #cfd6e4;
      background-color: $white;
      color: #58667e;
      cursor: pointer;
      z-index: 2;

      &::after {
        content: '';
        position: absolute;
        left: 6px;
        top: 1px;
        width: 6px;
        height: 12px;
        border: solid $white;
        z-index: 1;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }

    input:checked ~ .checkmark {
      background-color: $mainGreen !important;
      &:after {
        border-color: $white;
      }
    }
  }

  &__block-w-check {
    display: flex;
    flex-direction: column;
    width: 74%;
  }

  &__carbon-input {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    .input-field_input {
      margin-left: 21px;
      width: 70%;
    }
    .input-field_label {
      color: $mainText;
      font-weight: 400;
      line-height: 1.8;
    }
  }

  &__carbons-select {
    width: 74%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &-field {
      margin-left: 48px;
      width: 100%;
      max-width: 376px;
      &.error {
        & .select-react__control {
          border-color: $peach !important;
        }
      }

      .react-select__control {
        padding: 12px;
      }
    }
  }

  &__wrapper-btns {
    display: flex;
    margin-top: 30px;
    width: 100%;
    justify-content: space-evenly;
    button {
      flex-basis: 32%;
    }
  }

  &__baseline {
    display: flex;
    .edit-lec__checkbox {
      align-self: center;
      margin-left: 26px;
    }
  }

  &__lec-input {
    margin-bottom: 12px;
    &.newZone {
      & .input-field_input input  {
        border-color: $orange;
      }
    }

    &.between {
      margin-bottom: 5px;

      justify-content: space-between;
    }

    .input-field_input {
      max-width: 376px;
      width: 100%;
    }
  }

  &__baseline-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    & > span {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.2;
      color: $greenBold;
      margin-bottom: 10px;
    }
  }

  &__download-zone {
    @include theme-aware('background', 'mainGreen');
    color: $white;
    font-weight: 500;

    &:hover {
      background: $white;
      color: $mainGreen;
    }
  }

  &__component {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
  }

  &__btn-component {
    max-width: 172px;
    width: 100%;
  }

  &__popup-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 50px 66px 59px 66px !important;
  }

  &__popup-title-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__popup-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;
    color: $greenBold;
  }

  &__popup-desc {
    margin-top: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
    color: $mainText;
    span {
      font-weight: 700;
    }
  }

  &__popup-btns {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    width: 100%;
    & .btn {
      width: 100%;
      font-weight: 700;
      max-width: 253px;
    }
  }
}
