.sorting{
  display: grid;
  align-items: center;
  column-gap: 10px;
  border: 1px solid transparent;

  &__new{
    grid-template-columns:
    minmax(111px, 1fr)
    minmax(124px, 0.8fr)
    minmax(79px, 0.5fr)
    minmax(153px, 0.8fr)
    minmax(69px, 0.5fr)
   minmax(180px, 0.5fr)
    minmax(180px, 0.8fr)

  ;
    padding: 0 20px;
  }
  &__denied{
    grid-template-columns:
         minmax(112px, 1fr)
    minmax(110px, 0.8fr)
    minmax(70px, 0.5fr)
    minmax(70px, 0.8fr)
    minmax(24px, 0.8fr)
         minmax(70px, 0.5fr)
    minmax(160px, 0.5fr)
    minmax(160px, 0.8fr)
  ;
    padding: 0 20px;

  }
  &__approve{
    grid-template-columns:
 minmax(112px, 1fr)
    minmax(110px, 0.8fr)
    minmax(70px, 0.5fr)
    minmax(70px, 0.8fr)
    minmax(24px, 0.5fr)
     minmax(70px, 0.5fr)
  minmax(140px, 0.5fr)
    minmax(108px, 0.8fr)
    minmax(50px, 0.5fr);
  ;
    padding: 0 20px;
  }
  &__item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 4px;
  }

  &__text{
    font-size: 12px;
    font-weight: 700;
    line-height: 2;
    text-transform: uppercase;
    color : $greenBold;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
  }

  &__wrapper-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(0deg);
    &.active{
      transform: rotate(180deg);
    }

  }






}
