.layout {
  display: flex;
}

.content {
  width: 100%;
  // 64px header
  height: calc(100dvh - 64px);
  overflow: scroll;
}

.page {
  margin: 40px 40px 20px;
}
