.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 60px;
}

.permissions {
  width: 100%;

  &__title {
    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
    color: $darkBlue;
  }

  &__top-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    height: 62px;
  }

  &__button-title {
    font-weight: 700;
    line-height: 24px;
    color: $white;
  }

  &__buttons {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__search{
    margin-bottom: 0;
  }
  &__button {
    min-width: 245px;
    width: 245px;
    height: 100%;
  }

  &__modal {
    max-width: 500px;
  }
}

.list {
  width: 100%;
  min-width: 420px;
  padding-top: 40px;
}
