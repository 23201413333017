.login {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .inner-wrapper {
    display: grid;
    grid-template-rows: max-content minmax(48px, max-content) minmax(24px, max-content);
    gap: 50px;
    margin: 20px;

    .logo-block {
      display: flex;
      flex-direction: column;
      align-items: center;
  img{
    width: 112px;
    height:34px ;
    margin-bottom:27px ;
  }
      .title {
        text-align: center;
        font-weight: 700;
        font-size: 36px;
        line-height: 48px;
        color: $greenBold;
      }
    }

    .login-btn {
      justify-self: center;
      max-width: 456px;
      min-height: 48px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      border: none;
      outline: none;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;

      &:hover {
        opacity: 0.9;
        transition: opacity 0.3s ease-in-out;
      }
    }

    .message {
      justify-self: center;
      line-height: 24px;
      font-weight: 600;
      font-size: 20px;
      color: $lightRed;
    }
  }
}
