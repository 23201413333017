.email {
  &__btn {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      font-weight: 700;
      margin-right: 5px;
      line-height: 1.8;
      color : $mainGreen;
    }
  }

  &__popup {
    position: fixed;
    max-width: 298px;
    width: 100%;
    background: $mostLightGreen;
    color: $white;
    display: flex;
    justify-content: space-between;
    padding: 12px 19px 12px 39px;
    border-radius: 6px;
    font-weight: 600;
    line-height: 1.6;
    top:26px;
    left:50%;
    transform: translateX(-50%);
  }
}
