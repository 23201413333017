.table-column-names {
  display: flex;
  width: 100%;
  padding-left: 12px;
  margin-bottom: 10px;
  min-width: 500px;

  &__item {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: $greenBold;
  }
}
