.map{
  position: fixed;
  width: 100%;
  overflow: hidden;
  left: 0;
  top:0px;
  height: 100vh;
  opacity: 1;
  z-index: 6;
  transition: .3s;
  &__mini{
    position :relative;
    width: 100%;
    height: 300px;
    margin-top: 12px;
    margin-bottom: 30px;

  }

  &__box{
    height: 100%;
    width: 100%;
    @include theme-aware('background-color', 'color-background');

  }

  &__box-info{
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include theme-aware('background-color', 'color-background');

  }

  &__btn-wrapper {
    max-width: 200px;
    width: 100%;

    &__save {
      margin-top: 17px;
      margin-bottom: 10px;
    }
  }

  %shared-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  }

  &__init-text {
    @extend %shared-text;
    color: #5B6075;
  }

  &__error-text {
    @extend %shared-text;
    color: $mainGreen;
  }

  &__init-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #5B6075;

    &__details {
      margin-bottom: 27px;
    }
  }

  &__calculation-box {
    max-height: 570px;
    width: 415px;
    position: absolute;
    bottom: 30px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.89);
    padding: 30px;
    text-align: center;
    border-radius: 8px;
    transition: all .1s;

    & p {
      margin-bottom: 10px;
    }

    &__smaller {
      width: 330px;
    }

    &__closed {
      width: 124px;
      max-height: 108px;
      justify-content: center;
      display: flex;
      align-items: center;
    }
  }

  &__draw-polygon {
    width: 30px;
    vertical-align: text-bottom;
  }

  &__closeButton {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 30px
  }

  &__openButton {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px
  }
  &__loading {
    display: flex;
    position: fixed;
    z-index:10;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    i {
      margin-right: 20px;
      animation: ease 1s rotateLoad infinite ;
    }
    @include theme-aware('background-color', 'color-background');

  }

  &__map {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    & canvas{
      height: 100%!important;
      width: 100%!important;
    }
  }
  &__inside-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & span{
      padding: 2px;
    }
  }
}

.marker {
  width: 18px;
  height: 18px;
  background-color: $white;
  border-radius: 50%;
}

.mapboxgl-ctrl-geocoder {
  min-width: 415px !important;
  border-radius: 6px !important;
  height: 40px !important;

  svg {
    fill: #CECECD !important;
    top: 10px !important;
  }

  input {
    height: 40px !important;

    &::placeholder {
      color: #CECECD !important;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
//.mapboxgl-canvas {
//  pointer-events: none;
//}
.mapboxgl-ctrl-top-right {
  top: 0;
  right: 0;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mapboxgl-ctrl-group button {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  background-color: #329D9C;
  border-radius: 50%;
}

.mapboxgl-ctrl-group {
  background-color: transparent;
}

.mapboxgl-ctrl-group button+button {
  border-top: none;
}

.mapboxgl-ctrl-group:not(:empty) {
  box-shadow: none;
}

.mapbox-gl-draw_ctrl-draw-btn:hover {
  background-color: #329D9C !important;
}

.mapboxgl-ctrl-group button:focus:first-child,
.mapboxgl-ctrl-group button:focus:last-child {
  border-radius: 50%;
  opacity: 0.7;
}

.mapboxgl-ctrl-group:last-child {
  transform: rotate(180deg);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url('../../assets/plus.svg');
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url('../../assets/minus.svg');
}

.mapboxgl-ctrl-zoom-in:hover,
.mapboxgl-ctrl-zoom-out:hover {
  background-color: #329D9C !important;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
  top: 9px !important;
}
