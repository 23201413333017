.checkbox {
  height: auto;
  margin-bottom: 8px;

  &-info {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }

  &-title {
    font-weight: 500;
    line-height: 24px;
    color: $mainText;
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    &-label {
      margin-bottom: 8px;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    padding-left: 32px;
    height: 24px !important;
    position: relative;
    color: #58667e;
    cursor: pointer;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      border-radius: 8px;
      border: 1px solid #cfd6e4;
      background-color: transparent;
      color: #58667e;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }

    input:checked ~ .checkmark {
      background-color: $mainGreen !important;
    }
  }

  &.disabled {
    .checkbox__checkbox-title {
      font-weight: 500;
      line-height: 24px;
      color: $mainText;
    }

    input:checked ~ .checkmark {
      background-color: $grayMain !important;
    }
  }
}
