.phone {
  width: 100%;
  &__label {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 2px;
    color: $mainText;
  }
  .react-tel-input {
    padding: 14.5px;
    border: 1px solid $grayMain;
    border-radius: 6px;

    & .arrow {
      display: none;
    }
  }
  .flag-dropdown {
    background: $white;
    border: none !important;
    position: absolute;
    &:before {
      right: 0;
      content: '';
      position: absolute;
      height: 50%;
      width: 2px;
      background: $grayMain;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .selected-flag {
    background: $white;
    border: none;
  }

  .form-control {
    background: $white;
    border: none !important;
    color: $mainText;
    font-weight: 400;
    line-height: 26px;
    height: auto !important;
  }
}
