.icon-marker-circle:before {
  content: "\e914";
  color: #5b6075;
}
.icon-escalation-arrow:before {
  content: "\e906";
  color: #5b6075;
}
.icon-draw-polygon:before {
  content: "\e91b";
  color: #329d9c;
}
.icon-trash-alt2:before {
  content: "\e923";
  color: #329d9c;
}
.icon-check-square:before {
  content: "\e924";
  color: #329d9c;
}
.icon-check-circle:before {
  content: "\e92e";
  color: #329d9c;
}
.icon-info-circle:before {
  content: "\e92f";
  color: #fecb90;
}
.icon-closing-cross-circle:before {
  content: "\e930";
  color: #ff8080;
}
.icon-play-circle:before {
  content: "\e90f";
  color: #329d9c;
}
.icon-add-user:before {
  content: "\e913";
  color: #329d9c;
}
.icon-left-arrow-circle:before {
  content: "\e920";
  color: #fff;
}
.icon-left-arrow:before {
  content: "\e921";
  color: #1c505d;
}
.icon-search:before {
  content: "\e922";
  color: #cececd;
}
.icon-pencil:before {
  content: "\e937";
  color: #329d9c;
}
.icon-reload:before {
  content: "\e93c";
  color: #329d9c;
}
.icon-right-arrow:before {
  content: "\e940";
  color: #1c505d;
}
.icon-menu-burger:before {
  content: "\e94c";
  color: #329d9c;
}
.icon-trash:before {
  content: "\e94e";
  color: #D54F47;
}
.icon-plus:before {
  content: "\e939";
}
.icon-play:before {
  content: "\ea1c";
}
.icon-arrow-pointer:before {
  content: "\ea40";
}
.icon-account-circle:before {
  content: "\e900";
  color: #fff;
}
.icon-scheme:before {
  content: "\e901";
  color: #329d9c;
}
.icon-rect-plus:before {
  content: "\e902";
  color: #fff;
}
.icon-reload-circle:before {
  content: "\e903";
  color: #329d9c;
}
.icon-add-circle:before {
  content: "\e950";
  color: #329d9c;
}
.icon-add-photo:before {
  content: "\e904";
  color: #d5d9e7;
}
.icon-add:before {
  content: "\e905";
  color: #329d9c;
}
.icon-arrow-pointer-alt:before {
  content: "\e907";
  color: #1c505d;
}
.icon-cached:before {
  content: "\e908";
  color: #fff;
}
.icon-closing-cross:before {
  content: "\e909";
  color: #329d9c;
}
.icon-timer:before {
  content: "\e90a";
  color: #a6b0c3;
}
.icon-close:before {
  content: "\e90b";
  color: #ff8080;
}
.icon-pencil-alt:before {
  content: "\e90c";
  color: #d5d9e7;
}
.icon-trash-alt:before {
  content: "\e90d";
  color: #cecece;
}
.icon-success:before {
  content: "\e90e";
  color: #7be495;
}
.icon-info-alt:before {
  content: "\e910";
  color: #d5d9e7;
}
.icon-exclamation:before {
  content: "\e911";
}
.icon-event:before {
  content: "\e912";
  color: #1c505d;
}
.icon-tree:before {
  content: "\e919";
}
.icon-home:before {
  content: "\e941";
  color: #cecece;
}
.icon-info:before {
  content: "\e942";
  color: #329d9c;
}
.icon-my-location:before {
  content: "\e943";
  color: #4d4d4d;
}
.icon-case:before {
  content: "\e944";
  color: #d5d9e7;
}
.icon-question-marker:before {
  content: "\e945";
  color: #1c505d;
}
.icon-users-alt:before {
  content: "\e946";
  color: #329d9c;
}
.icon-marker:before {
  content: "\e947";
}
.icon-shield-search:before {
  content: "\e948";
  color: #329d9c;
}
.icon-clock:before {
  content: "\e949";
  color: #d5d9e7;
}
.icon-plug:before {
  content: "\e94a";
  color: #329d9c;
}
.icon-settings:before {
  content: "\e94b";
  color: #329d9c;
}
.icon-tree-alt:before {
  content: "\e94d";
  color: #329d9c;
}
.icon-users:before {
  content: "\e94f";
  color: #cdcece;
}
.icon-filter-arrow-up:before {
  content: "\e961";
  color: #d5d9e6;
}
.icon-filter-arrow-down:before {
  content: "\e962";
  color: #d5d9e6;
}
.icon-plus-cir:before {
  content: "\e963";
}
.icon-notepad:before {
  content: "\e952";
  color: #cecece;
}
.icon-shield-check:before {
  content: "\e953";
  color: #329d9c;
}
.icon-without-eye:before {
  content: "\e954";
}
.icon-eye:before {
  content: "\e955";
}
.icon-warning:before {
  content: "\e956";
  color: #ff8080;
}
