@import "./TableItem/TableItem";
@import "./TableItemPermissions/TableItemPermissions";
@import "./TableItemValidator/TableItemValidator";

.tabs {
  &-horisontal {
    display: flex;
  }
  &__menu-tab{
    display: flex;

  }
  &__left-menu{
  display: flex;
    align-items: center;
  }
  &__download-block{
    display: flex;
    width: 145px;
    align-items: center;
    margin-left: 8px;
  }
  &__csv{
    display: block;
    padding:12px;
    max-width: 100%;
    text-align: center;
    width: 100%;
    color: $white;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    transition: .3s;
    &.download{
      background: $grayLight;
      pointer-events: none;

    }
  }

  .tabs-header {
    display: flex;
    flex-direction: column;
    border-right: 1px solid $grayMain;
    width: 280px;
    font-weight: 500;

    &_horisontal {
      min-height: 46px;
      width: 100%;
      flex-direction: row;
      gap: 30px;
      border: none;
    }
  }

  .tabs-link {
    font-weight: 500;
    line-height: 21px;
    color: $color-font;
    display: flex;
    align-items: center;
    cursor: pointer;

    &__counter {
      font-weight: 700;
      font-size: 20px;
      line-height: 16px;
      padding: 7px 10px 7px 9px ;
      color: $orange;
      border-radius: 50px;
      background: rgba(250, 188, 115, 0.2);

      margin-left: 4px;
    }

    &_horisontal {
      font-weight: 700;
      font-size: 20px;
      color: $color-for-link;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: $greenBold;
    }

    &_img {
      height: 24px;
      width: 24px;
      margin-right: 11px;
    }
  }
}

.horizontal {
  flex-direction: row !important;
}

.tab {
  &-child {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &-content {
    width: 100%;
  }

  &-gap {
    margin-top: 30px;
  }
}

.val-modal {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white;
  }

  &__desc {
    display: flex;
  }

  &__button {
    width: 350px;
    &-title {
      font-weight: 700;
      line-height: 24px;
      color: $white;
    }
  }

  &__title {
    font-weight: 700;
    line-height: 21px;
    color: $darkBlue;
    margin-bottom: 16px;
  }

  &__msg {
    font-weight: 400;
    line-height: 21px;
    color: $darkBlue;
    margin-bottom: 24px;
    margin-left: 6px;
  }

  &__icon {
    height: 20px;
    width: 20px;
  }
}
