.icon {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

.input {
  background-color: transparent;
  line-height: 24px;
  border: none;
  &::placeholder {
    color: $mainInputColor;
  }
}

.search-wrapper {
  display: flex;
  flex-direction: row;
  height: 44px;
  border: 1px solid $grayMain;
  border-radius: 6px;
  align-items: center;
  padding: 13px 21px;
}

.search__input{
  &::placeholder {
    color: $grayMediumShade;
  }
}
