.table {
  &__column-names {
    display: flex;
    width: 100%;
    padding-left: 12px;
    margin-bottom: 10px;
    min-width: 500px;
  }

  &__24 {
    width: 24%;
  }

  &__19 {
    width: 19%;
  }

  &__column-name {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: $darkBlue;
    max-width: 140px;
    position: relative;
  }

  &__info-img {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__tooltip-text {
    line-height: 20px;
    font-weight: 300;
  }
}

.tooltip {
  max-width: 200px;
  opacity: 1 !important;
  text-align: center;
}
