.table {
  &__15 {
    width: 15%;
  }

  &__20 {
    width: 20%;
  }

  &__box {
    width: 100%;
    display: flex;
    align-items: center;
    height: 64px;
    border: 1px solid $grayMain;
    border-radius: 8px;
    padding-left: 12px;
    margin-bottom: 10px;
  }

  &__main-text {
    font-weight: 400;
    line-height: 21px;
    color: $mainText;
    display: flex;
    align-items: center;
    margin-top: 3px;
    max-width: 240px;
    overflow: scroll;
    white-space: nowrap;
    width: 100%;
    max-height: 44px;
    min-height: 44px;
    &::-webkit-scrollbar {
      width: 2px;
      height: 3px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      height: 10px;
      background-color: $mainGreen;
      border-radius: 3px;
    }
  }
}
