.registration {
  background: $white;
  padding: 20px 66px;

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: $darkBlue;
  }

  &__account {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 12px;
  }

  &__logo {
    height: 55px;
    position: relative;
    bottom: 4px;
    margin-right: 10px;
  }

  &__button-text {
    font-weight: 600;
    color: $white;
  }

  &__submit-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__cancel {
    background: $grayMain !important;
    border-radius: 8px !important;
    width: 47%;
  }

  &__submit {
    border-radius: 8px !important;
    width: 47%;
  }
}
