.switch-field {
  &__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
  }

  &__label {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $darkBlue;
  }
}
