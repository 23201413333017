.modal {
  position: fixed;
  animation: ease apperance 1s forwards;
  width: 100vw;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 15;

  &__bg {
    width: 100vw;
    z-index: 5;
    height: 100vh;
    opacity: 0.9;
    background: white;
  }

  &__wrapper {
    text-align: center;
    position: absolute;
    max-width: 440px;
    padding: 20px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    z-index: 10;
    background-color: $white;
  }

  &__wide {
    max-width: 600px;
  }

  &__logo {
    margin-bottom: 20px;

    i {
      font-size: 103px;
    }
  }

  &__desc {
    margin-inline: auto;

    & .title {
      font-size: 28px;
      font-weight: 700;
      color: $green;
    }

    & .text-block {
      margin: 10px auto 40px;
      max-width: 530px;

      & .text {
        color: $lightRed;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        opacity: 1;
      }

      & .paragraphIndents {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }
  }

  &__btn {
    max-width: 260px;
    margin: auto;
    padding-top: 20px;
  }
}
