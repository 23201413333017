* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

li {
  list-style-type: none;
}

h2 {
  font-size: 28px;
  line-height: 1.2;
}

a {
  &:hover {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:link {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }
}

input {
  font-family: inherit;

  &::placeholder{
    font-family: 'Poppins', sans-serif;
  }
}

button {
  font-family: 'Poppins', sans-serif;
}
