.carbonCard{
  margin-top: 13px;
  padding:16px 20px 16px 20px;
  border: 1px solid $grayMain;
  border-radius: 8px;
  display: grid;
  align-items: center;
  column-gap: 10px;
  grid-template-columns:
    minmax(111px, 1fr)
    minmax(124px, 0.8fr)
    minmax(79px, 0.5fr)
    minmax(153px, 0.8fr)
    minmax(69px, 0.5fr)
    minmax(180px, 0.5fr)
    minmax(180px, 0.8fr);


  &__btn {
    padding: 7px 21px ;
    border-radius: 8px;
    font-weight: 700;

    &:hover {
      background: $white;
      color: $mainGreen;
      border: 1px solid $mainGreen;
    }
  }

  &__text {
    font-weight: 400;
    line-height: 1.7;
    color: $mainText;
    overflow-x: scroll;
    white-space: nowrap
  ;
    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      height: 10px;
      background-color: $mainGreen;
      border-radius: 3px;
    }
  }

  &__denied{
    grid-template-columns:
       minmax(112px, 1fr)
    minmax(110px, 0.8fr)
    minmax(70px, 0.5fr)
    minmax(70px, 0.8fr)
    minmax(24px, 0.8fr)
       minmax(70px, 0.5fr)
    minmax(160px, 0.5fr)
    minmax(160px, 0.8fr)
  }

  &__approve{
    grid-template-columns:
     minmax(112px, 1fr)
    minmax(110px, 0.8fr)
    minmax(70px, 0.5fr)
    minmax(70px, 0.8fr)
    minmax(24px, 0.5fr)
     minmax(70px, 0.5fr)
    minmax(140px, 0.5fr)
    minmax(108px, 0.8fr)
    minmax(50px, 0.5fr);

    &.active{
      border-color: $orange;
      position : relative;
      &:before{
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 75px;
        background: $orange;
        position: absolute;
        left: -22px;
        transform: translate(-50%,-50%);
        top:50%
      }
    }
  }

  &__anchor{
    color: $mainText;

    &:hover{
      text-decoration: underline;
      color: $mainGreen;
    }
  }
  &__btn-view {
    padding: 7px 12px;
    max-width: 138px;
    font-weight: 700;
    background: $white;
    color: $mainGreen;
    border: 1px solid $mainGreen;
    &:disabled {
      background: $grayMain;
      cursor: auto;
    }
    &:hover {
      background: $mainGreen;
      color: $white;
      border: 1px solid $mainGreen;
    }
  }
  &__btn-approve {
    padding: 7px 12px;
    max-width: 138px;
    font-weight: 700;

    &:disabled {
      background: $grayMain;
      cursor: auto;
    }
  }

  &__btn-denied {
    padding: 6px 12px;
  }

  &__tooltip {
    max-width: 370px;
    max-height: 90px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      height: 2px;
      background-color: $mainGreen;
      border-radius: 3px;
    }

    &:before,&:after{
      display: none;
    }
  }

  &__edit {
    cursor: pointer;
    display: flex;
    color: $mainGreen;
    font-weight: 700;
    align-items: center;

    span {
      margin-right: 9px;
    }
  }

  &__approve-zone {
    padding: 6px 13px !important;
  }
}
