.item {
  min-height: 62px;
  border: 1px solid $grayMain;
  border-radius: 8px;
  font-weight: 500;
  line-height: 16px;
  transition: height 500ms ease;

  &-field {
    margin-bottom: 24px;
    &_name {
      font-weight: 700;
      color: $greenBold;
      margin-right: 10px;
    }
  }

  &-comment {
    margin-top: 16px;
  }
  &-title{
    color:$color-font;
  }

  &-textarea {
    border: 1px solid $grayMain;
    border-radius: 8px;
    padding: 20px;
    margin-top: 17px;
  }

  &-approve {
    width: 142px;
    height: 44px;
    font-weight: 700;
    background: $mainGreen;
    border-radius: 6px;
    text-transform: uppercase;
    margin-right: 16px;
  }

  &-reject {
    text-transform: uppercase;
    width: 142px;
    height: 44px;
    font-weight: 700;
    background: $peach;
    border-radius: 6px;
  }

  &-important {
    font-weight: 700;
    font-size: 16px;
    span{
      color: $hugeBlue;
    }
    color: $greenBold;
    margin-right: 10px;
  }

  &-comment_msg {
    font-weight: 700;
    font-size: 16px;
    color: $darkBlue;
  }

  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    width: 100%;
    padding-left: 20px;
    padding-right: 28px;
    cursor: pointer;
  }

  .item-content {
    display: flex;
    flex-direction: column;
    line-height: 16px;
    padding: 0 20px 0;
    margin-bottom: 8px;
  }

  .hide {
    display: none;
  }

  .btn-box {
    margin: 16px 0 24px;
  }

  .textarea {
    resize: none;
    width: 100%;
    min-height: 91px;
    padding: 13px 19px;
    margin: 17px 0;
    border: 1px solid $grayMain;
    border-radius: 6px;
    font-weight: 400;
    color: $mainText;
    position: relative;
  }
  &__popup {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__popup-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;
    color: $mainText;
  }
  &__popup-desc {
    font-weight: 400;
    font-size: 24px;
    line-height: 1.4;
    color: $greenBold;
    max-width: 401px ;
    span {
      font-weight: 700;
    }
  }
  &__popup-wrapper {
    max-width: 640px;
  }
  &__popup-btns {
    display: flex;
    justify-content: space-between;
    margin-top: 56px;
    width: 100%;
    & .btn {
      width: 100%;
      max-width: 294px;
      border-radius: 6px!important;
    }
  }
  &__popup-btn {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
    & .btn {
      width: 100%;
      max-width: 294px;
    }
  }
}
