.select {
  z-index: 20;
  position: relative;
  margin-bottom: 12px ;
  &__group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__toolkit{
    z-index: 20;
    position: relative;
    display: flex!important;
    padding:2px 12px!important;
    width: 100%!important;
    margin: 0px!important;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $grayMain!important;
    border-radius: 8px;
    color:$mainText;
    &:hover{
      background: $bg_select;
    }
    &.active{
      background: $bg_select;
    }
    .leakage__checkbox{
      padding-right: 0px;
    }
  }
  &__label{
    font-weight: 400;
    line-height: 1.5;
    color:$mainText
  }
  &-react{
    &__control{
      padding: 5px;
      border-color: $grayMain!important;
      border-radius: 8px!important;
      color:$mainText!important;
    }
    &__indicator-separator{
      display: none;
    }
  }

}
