.confirmation {
  background: $white;
  padding: 20px 66px;

  &__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &__text-container {
    text-align: center;
    width: 290px;
  }

  &__question {
    font-weight: 400;
    font-size: 20px;
    color: $darkBlue;
    width: 100px;
    line-height: 32px;
  }

  &__button-text {
    font-weight: 600;
    color: $white;
  }

  &__buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;
  }

  &__no {
    background: $grayMain !important;
    border-radius: 8px !important;
    width: 47%;
  }

  &__yes {
    border-radius: 8px !important;
    width: 47%;
  }
}
