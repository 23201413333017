.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 60px;
}

.validators {
  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: $greenBold;
  }

  &__top-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__button-title {
    font-weight: 700;
    line-height: 24px;
    color: $white;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  &__button {
    min-width: 245px;
    border-radius: 6px;
  }

  &__list {
    width: 50%;
    min-width: 420px;
  }

  &__phase {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    color: $greenBold;
    font-weight: 500;
  }
}
