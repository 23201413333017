.eval {
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 40px;
  }

  &__wrapper {
    max-width: 1038px;
    margin: 0 auto 50px;
    display: flex;
    flex-direction: column;
  }
  &__files{
    font-size: 12px;
    li {
      line-height: 18px;
      margin-bottom: 5px;
    }
    span {
      color: $mainText;
      cursor: pointer;
      &:hover{
        opacity: 0.75;
        transition: opacity 0.3s ease-in-out;
      }
    }

  }
  &__block {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__csv{
    display: block;
    padding: 10px;
    margin-bottom: 10px;
    max-width: 380px !important;
    text-align: center;
    color: $white;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
  }
  &__csv-wrapper{
    justify-content: flex-start;
    display: flex;
  }

  &__block-wrapper {
    padding: 28px 47px;
    border: 1px solid $grayMain;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    gap: 20px;
  }

  &__container {
    padding: 28px 47px;
    border: 1px solid $grayMain;
    border-radius: 6px;
    justify-content: space-between;
    align-items: baseline;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 24px;
    & > span {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: $greenBold;
    }
  }

  &__block-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: $greenBold;
  }

  &__wrapper-btn {
    width: 118px;
    margin-right: calc(-118px - 20px);
  }

  &__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $grayMain;
    padding: 10px 19px;
    height: 44px;
    font-size: 14px;
    font-weight: 700;
  }

  &__field {
    display: flex;
    flex-direction: column;
    flex-basis: 22%;
    width: 22%;

    &.capitalize {
      text-transform: capitalize;
    }

    &.not-out {
      .eval__field-text {
        overflow-x: auto;
        white-space: normal;
      }
    }

    &.one-line {
      .eval__field-text {
        max-height: 61px;
      }
    }

    &.full {
      flex-basis: 100%;
      width: 100%;
    }

    &.half {
      flex-basis: 46%;
      width: 46%;
    }

    &.most-text {
      flex-basis: 100%;
      width: 100%;

      .eval__field-text {
        width: 46%;
      }
    }

    &.textarea {
      .eval__field-text {
        min-height: 124px;
      }
    }
  }

  &__wrapper-btns {
    display: flex;
    margin: 20px 0;
    width: 100%;
    justify-content: space-evenly;
    button {
      flex-basis: 42%;
      max-width: 385px;
      font-weight: 700;
      font-size: 14px;
      border-radius: 6px;
      padding: 13px 10px;
    }
  }

  &__field-label {
    font-weight: 700;
    line-height: 1.5;
    color: $greenBold;
    font-size: 14px;
    margin-bottom: 20px;
  }

  &__field-text {
    padding: 18px 23px;
    border: 1px solid $grayMain;
    border-radius: 8px;
    width: 100%;
    overflow-x: scroll;
    min-height: 48px;
    white-space: break-spaces;
    font-size: 14px;
    color: $mainInputColor;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      height: 10px;
      background-color: $mainGreen;
      border-radius: 3px;
    }
  }

  &__field-placeholder {
    padding: 18px 23px;
    border: 1px solid $grayMain;
    border-radius: 8px;
    font-size: 12px;
    color: $grayLight;
    user-select: none
  }

  &__info {
    width: 100%;
    display: flex;
    column-gap: 80px;

    &-title {
      font-weight: 700;
      color: $greenBold;
      font-size: 14px;
    }

    &-text {
      font-size: 14px;
      font-weight: 400;
    }

    &-smallImgBlock {
      width: 50%;

      &-imgSize {
        width: 203px;
        height: 174px;

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
        }
      }
    }

    &-documentation {
      width: 50%;
      font-size: 12px;
      li {
        line-height: 18px;
        margin-bottom: 5px;
      }
      a {
        color: $mainText;
      }
    }
  }

  &__modal-image {
    width: 100%;
    height: 400px;
    margin-top: 24px;
  }


  &__modal-map {
    width: 100%;
    height: 400px;
    margin-top: 24px;
  }

  &__steps-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
    position: relative;
    margin-bottom: 40px;

    &:before {
      content: "";
      width: 100%;
      height: 2px;
      background: $grayMain;
      position: absolute;
      bottom: 0;
    }
  }

  &__step {
    font-weight: 600;
    line-height: 1.6;
    color: $mainText;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    padding-bottom: 16px;
    &.active {
      color: $mainGreen;
      &:before {
        content: "";
        width: 100%;
        height: 2px;
        background: $mainGreen;
        position: absolute;
        bottom: 0;
      }
    }
  }

  &__checkbox {
    display: flex;
    padding-left: 35px;
    align-items: center;
    padding-right: 32px;
    height: 24px !important;
    position: relative;
    color: $mainInputColor;
    cursor: pointer;
    user-select: none;

    span {
      text-transform: capitalize;
    }

    &.not-use {
      span {
        opacity: 0.6;
        color: $grayMain;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: -0px;
      height: 22px;
      width: 22px;
      border-radius: 3px;
      border: 1px solid #cfd6e4;
      background-color: $white;
      color: $greenBold;
      cursor: pointer;
      z-index: 2;

      &::after {
        content: "";
        position: absolute;
        left: 6px;
        top: 1px;
        width: 6px;
        height: 12px;
        border: solid $white;
        z-index: 1;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }

    input:checked ~ .checkmark {
      background-color: transparent !important;
      &:after {
        border-color: $greenBold;
      }
    }
  }

  &__gen-field {
    .eval__field-text {
      line-height: 20px;
      padding: 12px 12px;
    }
  }

  .textarea {
    .eval__field-text {
      white-space: break-spaces;
    }
  }

  &__gen-checkboxs {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & > label {
      margin-bottom: 0;
    }

    & > span {
      font-weight: 700;
      line-height: 1.5;
      color: $greenBold;
    }
  }

  &__activity-title {
    font-weight: 700;
    line-height: 16px;
    color: $greenBold;
  }

  &__activity-subtitle {
    font-weight: 600;
    line-height: 12px;
    color: $greenBold;
    margin-top: 10px;
  }

  &__activity-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }

  &__activity-field {
    &.projA {
      border-radius: 8px;
    }

    .eval__field-text {
      padding: 16px;
      color: $mainText;
      overflow-x: scroll;
      white-space: nowrap;
    }

    & .eval__field-label {
      font-weight: 700;
      line-height: 24px;
      color: $greenBold;
      margin-bottom: 20px;
    }

    & .eval__field-subLabel {
      color: $mainText;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 2px;
    }
  }

  &__wrapper-phone {
    flex-basis: 46%;
    width: 46%;
  }

  &__risks {
    &-title {
      font-weight: 700;
      font-size: 16px;
      color: $mainGreen;
      margin-bottom: 12px;
    }
  }

  &__field-block {
    display: grid;
    gap: 20px;
    color: $mainText;

    &-title {
      font-weight: 700;
      color: $greenBold
    }

    &-field {
      & p {
        padding: 12px;
      }
    }
  }
}
