.info {
  width: 100%;

  &__text {
    pointer-events: none;
    transition: 0.5s;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: $greenBold;
  }

  &__top-block {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  &__stats {
    display: flex;
    gap: 50px;

    &-stat {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: $greenBold;
    }
  }

  &__controls {
    display: flex;
    gap: 50px;
    justify-content: flex-end;
    width: 50%;
  }
  &__search {
    .input {
      width: 245px;
    }
  }
}

.table {
  &__column-names {
    display: flex;
    width: 100%;
    padding-left: 12px;
    margin-bottom: 10px;
  }
  .download-zone__btn{
    padding: 7px 16px;

  }

  &__15 {
    width: 15%;
    padding-right: 5px;
  }
  &__10 {
    width: 10%;
    padding-right: 5px;
  }

  &__20 {
    width: 20%;
    padding-right: 5px;
  }

  &__column-name {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: $darkBlue;
  }

  &__boxes {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    &-info {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $greenBold;
    }
  }

  &__box {
    width: 100%;
    display: flex;
    align-items: center;
    height: 64px;
    border: 1px solid $grayMain;
    border-radius: 8px;
    padding-left: 12px;
    margin-bottom: 10px;
  }

  &__main-text {
    font-weight: 400;
    line-height: 21px;
    color: $grayMain;
  }

  &__loader-init {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  &__loader-more {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}

.csv {
  &__title {
    color: $mainGreen;
    font-weight: 700;
  }

  &__button {
    width: 180px;
    height: 44px;
    background-color: $white;
    border: 1px solid $mainGreen;
    border-radius: 6px;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 60px;
}
