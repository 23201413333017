.settings {
  width: 100%;

  &__content {
    border: 1px solid $grayMain;
    border-radius: 8px;
    padding: 26px 30px;
    margin-bottom: 25px;
  }

  &__text {
    pointer-events: none;
    transition: 0.5s;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }

  &__title {
    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
    color: $darkBlue;
    padding-bottom: 18px;
  }

  &__top-block {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    height: 62px;
  }

  &__controls {
    display: flex;
    width: 50%;
  }

  &__search {
    .input {
      width: 245px;
    }
  }

  &__button-save {
    min-width: 245px;
    height: 44px;

    &-title {
      font-weight: 700;
      line-height: 15px !important;
    }
  }

  &__button-edit {
    height: 44px;
    min-width: 245px;
    background: $white !important;
    border: 1px solid $mainGreen !important;
    align-items: center;
    justify-content: center;
    display: flex;
    &-active {
      background: $mainGreen !important;
      border: none !important;
      .settings__button-edit-title {
        color: $white !important;
      }
    }

    &-title {
      color: $darkGreen;
      font-weight: 700;
      line-height: 15px !important;
      margin-left: 5px;
    }
  }

  &__pencil {
    height: 15px;
    padding-right: 6px;
  }

  &__modal {
    width: 100%;
    display: flex;
    margin-top: 56px;

    &-content {
      background-color: $white;
    }

    &-no {
      width: 290px;
      height: 48px;
      margin-right: 20px;
    }
    &-yes {
      width: 290px;
      height: 48px;
    }

    &-text {
      font-weight: 400;
      font-size: 18px;
      color: $darkBlue;
    }
  }

  &__maintenance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-field {
      border: 1px solid $grayMain;
      border-radius: 8px;
      padding: 26px 30px;
      width: 60%;
    }

    &-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $color-font;
      margin-right: 35px;
    }
  }
}

.settings__modal-no {
  width: 290px !important;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 60px;
}
