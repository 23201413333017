.scaler {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 30px;

  &.active {
    .scaler__popup {
      opacity: 1;
      overflow: visible;
      z-index: 20;

    }
  }

  &__wrapper {
    height: 304px;
    width: 100%;
  }

  &__prev {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }
  &__icon {
    width: 100%;
    z-index: 5;
    height: 100%;
    position: absolute;
    top: 0;
    left:0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      background: $dark;
      content: '';
      width: 100%;
      z-index: 1;
      height: 100%;
      opacity: 0.1;
      position: absolute;
      top: 0;
      left:0;
    }
    i {
      cursor: pointer;
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      height: 27px;
      width: 27px;
      background-color: $mainGreen;
      border-radius: 50%;
    }
    i::before {
      color: $white;
    }
  }

  &__popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    transition: .4s;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
  }

  &__img {
    width: 80%;
    height: 80%;
    margin:auto;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    z-index: 10;
    & img{
      width: 100%;
      height: 100%;
    }
  }

  &__bg {
    width: 100vw;
    z-index: 5;
    height: 100vh;
    opacity: 0.9;
    background: $dark;
    position: absolute;
    top: 0;
    left:0;
  }

  &__close {
    z-index: 11;
    width: 16px;
    height: 16px;
    position: absolute;
    cursor: pointer;
    right: 20px;
    top:20px;
    i {
      font-size: 24px;
    }
    i::before {
      color: $white;
    }
  }
}
