.submit-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.msg {
  height: 16px;
  font-size: 16px;
  line-height: 14px;
  margin-top: 4px;
}

.cancel {
  background: $grayMain !important;
  border-radius: 8px !important;
  width: 47%;
}

.submit {
  border-radius: 8px !important;
  width: 47%;
}

.button-text {
  font-weight: 600;
  color: $white;
}

.title {
  font-weight: 700;
  font-size: 12px;
  line-height: 21px;
  color: $darkBlue;
}

.validator-form {
  background: $white;
}
