.footer {
  width: max-content;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: $grayMedium;

  &.absolute-left {
    position: absolute;
    left: 20px;
    bottom: 20px;

    @media only screen and (max-width: 480px) {
      left: 10px;
      bottom: 10px;
    }
  }

  &.center {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  &.absolute-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
  }

  &.inMenu {
    padding: 10px;
  }
}
