.btn {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $white;
  width: 100%;
  padding: 10px 10px;
  cursor: pointer;
  text-align: center;
  background: $mainGreen;
  border: 1px solid $white;
  border-radius: 5px;
  transition: background 0.5s;

  &:disabled {
    background: $grayMain;
  }

  &.deny {
    background: $peach;
  }

  &.cancel {
    background: $grayMain;
  }
}
