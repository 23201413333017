.leakage {
  &__wrapper {
    max-width: 880px;
    margin: auto;
    display: flex;
    flex-direction: column;
  }
  &__block {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    position: relative;
  }
  &__block-wrapper {
    padding: 28px 40px;
    border: 1px solid $grayMain;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 24px;
    & > span {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: $greenBold;
    }
  }
  &__wrapper-btn {
    width: 118px;
    margin-right: calc(-118px - 20px);
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__form-block {
    display: flex;
    justify-content: space-between;
    & > span {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.2;
      color: $greenBold;
      margin-bottom: 10px;
    }
    &.col {
      flex-direction: column;
    }
  }
  &__btn {
    display: flex;
    background: $grayMain;
    justify-content: space-between;
    align-items: center;
    padding: 15px 19px;
    max-height: 44px;
    span {
      margin-left: 10px;
      font-weight: 700;
      line-height: 1.7;
    }
  }
  &__input {
    width: 50%;
    flex-basis: 50%;
    input::placeholder{
      color:$grayLight;
    }
    &.pre-full {
      width: 100%;
      max-width: 585px;
      flex-basis: 100%;
    }
    &.full {
      width: 100%;
      flex-basis: 100%;
    }
  }
  &__textarea {
    padding: 15px 12px;
    background-color: white;
    border: 1px solid #d5d9e7;
    border-radius: 8px;
    min-height: 91px;
    font-weight: 400;
    resize: none;
    font-family: 'Poppins';

    &:focus ~ .text-field_label,
    &:valid ~ .text-field_label {
      color: #00dfc4;
      transform: translateX(10px) translateY(-7px);
      font-size: 12px;
      line-height: 18px;
      padding: 0 10px;
      background: white;
      border-left: 1px solid #329d9c;
      border-right: 1px solid #329d9c;
    }

    &:focus,
    &:valid {
      border-color: #d5d9e7;
    }
    &::placeholder{
      color:$grayLight;
    }
    &.error {
      border-color: $peach;
    }
  }
  &__checkbox {
    display: flex;
    padding-left: 35px;
    margin-left: 24px;
    align-items: center;
    height: 24px !important;
    position: relative;
    color: $greenBold;
    cursor: pointer;
    user-select: none;
    &-zone{
      padding-right: 0;
    }
    &:nth-child(n-1) {
      margin-top: 10px;
    }
    span {
      text-transform: capitalize;
    }
    &.not-use {
      span {
        opacity: 0.6;
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: -0px;
      height: 22px;
      width: 22px;
      border-radius: 3px;
      border: 1px solid #cfd6e4;
      background-color: $white;
      color: #58667e;
      cursor: pointer;
      z-index: 2;

      &::after {
        content: '';
        position: absolute;
        left: 6px;
        top: 1px;
        width: 6px;
        height: 12px;
        border: solid $white;
        z-index: 1;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }

    input:checked ~ .checkmark {
      background-color: $mainGreen !important;
      &:after {
        border-color: $white;
      }
    }
  }
  &__block-w-check {
    display: flex;
  }

  &__carbon-input {
    max-width: 585px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .input-field_input {
      width: 100%;
      max-width:376px;
      ::placeholder{
        color:$grayLight;
      }
    }
    &.rainfall{
      max-width: 585px;
      width: 100%;
    }
    .input-field_label {
      color: $mainText;
      font-weight: 400;
      line-height: 1.8;
    }
  }
  &__carbons-select {
    max-width: 585px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &-field {
      margin-left: 48px;
      width: 100%;
      max-width:376px;
      &.error {
        & .select-react__control {
          border-color: $peach !important;
        }
      }
      .react-select__control {
        padding: 12px;
      }
    }
  }

  &__wrapper-btns {
    display: flex;
    margin-top: 30px;
    width: 100%;
    justify-content: space-between;
    button {
      flex-basis: 33%;
      max-width: 254px;
      border-radius: 6px;
      font-weight: 700;
    }
  }
  &__baseline {
    display: flex;
  }

  &__lec-input {
    margin-bottom: 12px;
    .input-field_input {
      ::placeholder{
        color:$grayLight;
      }
      max-width: 376px;
      width: 100%;
      margin-left: 0;
    }
  }

  &__baseline-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    & > span {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.2;
      color: $greenBold;
      margin-bottom: 10px;
    }
  }
  &__download-zone {
    @include theme-aware('background', 'mainGreen');
    color: $white;
    padding: 7px 5px;
    font-weight: 400;
    &:hover {
      background: $white;
      color: $mainGreen;
    }
  }
  &__popup {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__popup-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;
    color: $mainText;
  }

  &__popup-desc {
    margin-top: 10px;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.4;
    color: $greenBold;
    span {
      font-weight: 700;
    }
  }

  &__popup-btn{
    max-width: 260px;
    margin-top: 30px;
  }

  &__popup-wrapper {
    max-width: 640px;
  }

  &__popup-btns {
    display: flex;
    justify-content: space-between;
    margin-top: 68px;
    width: 100%;
    & .btn {
      width: 100%;
      max-width: 294px;
      border-radius: 6px!important;
      font-weight: 700;
      min-height: 48px;
    }
  }

  &__edit-lec-btn {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
    & .btn {
      width: 100%;
      max-width: 294px;
      font-weight: 700;
    }
  }
}
