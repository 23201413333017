.filters{

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  &__csv{
    display: block;
    padding: 8px;
    max-width: 142px !important;
    text-align: center;
    width: 100%;
    color: $white;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    transition: .3s;
    &.download{
      background: $grayLight;
      pointer-events: none;

    }
  }
  &__item-wrapper{
    max-width: 279px;
    width: 279px;
    & label {
      text-transform: uppercase;
      color : $greenBold;
      font-size: 12px;
      font-weight: 700;
    }
  }
  &__items-wrapper{
    display: flex;
    gap: 30px;
    width: 100%;
    &.last{
      justify-content: flex-end;
    }
  }

  &__btn-wrapper{
    max-width: 142px;
    width: 100%;
    & .reset-filters{
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;

    }
  }
}
